function isArabic(text) {
	var pattern = /[\u0600-\u06FF\u0750-\u077F]/
	let arabic = pattern.test(text)

	// We need to test the Assyrian language too — call Syriac Alphabet

	var syriacPattern = /[\u0700-\u074F]/
	let syriac = syriacPattern.test(text)
	
	return arabic || syriac
}

export default isArabic