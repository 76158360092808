import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import resolveLink from '@utils/resolveLink'
import Link from 'next/link'
import BlockContent from '@sanity/block-content-to-react'
import Button from '@components/Button'
import { css } from '@emotion/react'
import { ULI, BulletPoint, OLI } from '@styles/global'
import Quote from '@components/Quote'
import ModuleStyles from '@styles/ModuleStyles'
import Image from '@components/Image'
import toPlainText from '@utils/toPlainText'
import isArabic from '@utils/isArabic'
import isPunjabi from '@utils/isPunjabi'


const BlockRenderer = (props) => {
	// eslint-disable-next-line react/prop-types
	if(isArabic(props.children.join(' '))) {
		return <div dir="rtl">{BlockContent.defaultSerializers.types.block(props)}</div>
	}
	// eslint-disable-next-line react/prop-types
	if(isPunjabi(props.children.join(' '))) {
		return <div className="punjabi">{BlockContent.defaultSerializers.types.block(props)}</div>
	}
	// Fall back to default handling
	return BlockContent.defaultSerializers.types.block(props)
}

const serializers = {
	marks: {
		internalLink: props => {
			return <Link href={resolveLink(props.mark.page) ?? ''}>{props.children}</Link> 
		},
		link: props => {
			if(props.mark.linkType === 'internal'){
				return <Link href={resolveLink(props.mark.document)}>{props.children}</Link> 
			} else if(props.mark.linkType === 'file'){
				return <a href={props.mark?.file?.asset?.url} target='_blank' rel='noreferrer'>{props.children}</a>
			} else if(props.mark.linkType === 'external'){
				return <a href={props.mark?.url} target={props.mark.blank ? '_blank' : '_self'} rel='noreferrer'>{props.children}</a>
			}
		},
		strong: props => {
			return <span css={css`font-variation-settings: 'wght' 700; font-weight: 700;`}>{props.children}</span>
		},
		sup: props => {
			return <sup>{props.children}</sup>
		},
	},
	types: {
		block: BlockRenderer,
		button: props => {
			return (
				<div css={css`
					margin: var(--s) 0;
					:not(:last-of-type){
						margin-bottom: 0;
					}
				`}>
					<Button 
						link={props.node.link} 
						outline={props.node.style === 'box' || !props.node.style}
						icon={props.node.style === 'arrow' && 'Rarr'}
						flex={props.node.style === 'arrow' }
						css={css`
							min-width: ${props.node.style === 'arrow' ? '0px' : '265px'};
							padding: ${props.node.style === 'arrow' ? 'var(--xxs) 0' : '6px var(--xs)'};
					`}
					>
						{props.node.text}
					</Button>
				</div>
			) 
		},
		quote: props => {
			return (
				<Quote quote={props.node.quote} attribution={props.node.attribution} light />
			)
		},
		imageWithCaption: props => {
			return (
				<ImageBlock>
					<Image image={props.node}/>
					{(props.node?.caption && props.node?.caption?.length > 0) && 
						<Caption className='b2'><RichText content={props.node?.caption}/></Caption>
					}
				</ImageBlock>
			)
		}
	},
	listItem: (props) =>
		(props.node.listItem === 'bullet' ? (
			<ULI css={css`margin-bottom: 0.8em; &:last-of-type{ margin-bottom: var(--s);}`}>
				<BulletPoint/>
				<div>
					{props.children}
				</div>
			</ULI>
		) : (
			<OLI css={css`margin-bottom: 0.8em; &:last-of-type{ margin-bottom: var(--s);}`}>
				<span>{props.children}</span>
			</OLI>
		)),
}

const RichText = ({ content, moduleStyles }) => {
	const textContent = content?.filter(item => item._type !== 'button')
	const containsArabic = isArabic(JSON.stringify(textContent))
	return (
		<div dir={containsArabic ? 'rtl' : 'ltr'}>
			{(
				moduleStyles ? 
					<ModuleStyles>
						<BlockContent blocks={content} serializers={serializers} ignoreUnknownTypes={true}/>
					</ModuleStyles>
					:
					<BlockContent blocks={content} serializers={serializers} ignoreUnknownTypes={true}/>
			)}
		</div>
	)
}

const Caption = styled.div`
	margin-top: var(--xs);
`
const ImageBlock = styled.div`
	margin-bottom: var(--s);
`

RichText.propTypes = {
	content: PropTypes.array,
	moduleStyles: PropTypes.bool,
}


export default RichText
